
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getBranchschools } from "../../utils/common";

const qs = require("qs");

import { Caxios } from "./../../utils/axios";

@Component({
  name: "Course",
  computed: {
    ...mapGetters("course", [
      "yearList",
      "grade",
      "subject",
      "termtype",
      "loadMoreFlag"
    ]),
    ...mapGetters("common", ["branchSchoolId"])
  },
  methods: {
    ...mapMutations("course", ["setSelectObj", "setLoadMoreFlag"]),
    ...mapActions("course", ["setAjaxData", "setYearList"])
  }
})
export default class Course extends Vue {
  yearList!: Array<string>;
  grade!: Array<any>;
  subject!: Array<any>;
  termtype!: Array<any>;
  branchSchoolId!: number;
  setSelectObj!: any;
  setAjaxData!: any;
  setYearList!: any;
  loadMoreFlag!: boolean;
  setLoadMoreFlag!: any;

  private activeFlaglagArr: Array<number> = [0, 0, 0, 0];
  private queryObj: any = {
    limit: 100,
    page: 1,
    branch_school_id: 0,
    grade: "",
    subject: "",
    termtype: "",
    year: ""
  };
  public personInfo: any = localStorage.getItem('fangtian_personal_Data') || ''

  private queryObjStr: any = {
    year: "",
    grade: "",
    subject: "",
    termtype: ""
  };

  private curGrade: Array<any> = [];
  private curSubject: Array<any> = [];

  private courseList: Array<any> = [];
  private loadingFlag = true;

  private allTotalPage = 0;

  @Watch("branchSchoolId", { immediate: true, deep: true })
  branchSchoolIdWatch(newVal: number, oldVal: number): void {
    if (oldVal) {
      this.restQuery();
    }
    if (newVal) {
      this.queryObj.branch_school_id = newVal;
      this.initQueryObj();
      if (this.grade) {
        this.initFilterGrade();
      }
      if (this.subject) {
        this.initFilterSubject();
      }
    }
    this.setSelectObj({});
  }
  @Watch("grade", { immediate: true, deep: true })
  gradeWatch(newVal: any): void {
    if (newVal && Array.isArray(newVal) && newVal.length > 0) {
      this.initFilterGrade();
    }
  }
  @Watch("subject", { immediate: true, deep: true })
  subjectWatch(newVal: any): void {
    if (newVal && Array.isArray(newVal) && newVal.length > 0) {
      this.initFilterSubject();
    }
  }

  @Watch("loadMoreFlag", { immediate: true, deep: true })
  loadMoreFlagWatch(newVal: boolean): void {
    if (newVal && this.queryObj.page < this.allTotalPage) {
      this.queryObj.page += 1;
      this.getCourse(1);
    }
  }

  private restQuery(): void {
    const _arr: Array<string> = ["year", "grade", "subject", "termtype"];
    _arr.forEach((attr: string): void => {
      this.queryObj[attr] = "";
      this.queryObjStr[attr] = "";
    });

    localStorage.removeItem("fangtian_course_query");
    localStorage.removeItem("fangtian_course_query_obj_str");
  }
  private async getCourse(type: number) {
    const data: any = { ...this.queryObj },
      _this: any = this;
    Object.keys(data).forEach((attr: string): void => {
      if (data[attr] == "No limit") {
        delete data[attr];
      }
    });
    this.loadingFlag = true;
    const result: any = await Caxios.get({
      url: `/api/rightcourses?${qs.stringify(data)}`
    });
    if (
      result &&
      result.records &&
      Array.isArray(result.records) &&
      result.records.length > 0
    ) {
      if (type == 0) {
        this.courseList = result.records;
      } else {
        result.records.forEach((item: any): void => {
          _this.$set(this.courseList, this.courseList.length, item);
        });
      }

      let _temp: Array<string> = ["No limit"],
        _unSort: Array<string> = [];
      this.courseList.forEach((item: any): void => {
        let _str = "";
        if (item.start_time) {
          _str = item.start_time.substr(0, 4);
          _unSort.push(_str);
        }
      });
      _unSort = Array.from(new Set(_unSort)).sort(
        (a: string, b: string): any => {
          return Number(a) - Number(b);
        }
      );
      _temp = [..._temp, ..._unSort];
      // this.setYearList(_temp)

      this.setLoadMoreFlag(false);
      console.log(this.courseList, "返回课程列表");
    } else {
      this.courseList = [];
    }
    this.allTotalPage = result.pages;
    this.loadingFlag = false;
  }
  // 获取年份
  private async getYears() {
    const branchschoolid: string = this.queryObj.branch_school_id;
    const result: any = await Caxios.get({
      url: `/api/datadict/YEAR?branch_school_id=${branchschoolid}&code=YEAR`
    });
    console.log(result, "返回请求结果");
    if (result?.length > 0) {
      const years = result.map((item: any): string => item.text);
      this.setYearList(years);
    }
  }
  private formatLocalstorage(): void {
    localStorage.setItem(
      "fangtian_course_query",
      JSON.stringify(this.queryObj)
    );
    localStorage.setItem(
      "fangtian_active_flaglag_arr",
      this.activeFlaglagArr.join(",")
    );
    localStorage.setItem(
      "fangtian_course_query_obj_str",
      JSON.stringify(this.queryObjStr)
    );
  }
  private changeSelect(idx: number, itm: any, type: number) {
    this.$set(this.activeFlaglagArr, type, idx);
    switch (type) {
      case 0:
        this.queryObj.year = itm;
        this.queryObjStr.year = itm;
        break;
      case 1: // 年级
        this.queryObj.grade = itm.id;
        this.queryObjStr.grade = itm.text;
        this.filterSubject();
        break;
      case 2: // 学科
        this.queryObj.subject = itm.id;
        this.queryObjStr.subject = itm.text;
        this.filterGrade();
        break;
      case 3:
        console.log(itm.text, itm.id, "。、。、。、。、");
        this.queryObj.termtype = itm.id;
        this.queryObjStr.termtype = itm.text;
        break;
    }
    this.formatLocalstorage();
    this.queryObj.page = 1;
    this.getCourse(0);
  }
  private initFilterGrade(): void {
    this.curGrade = [];
    if (this.branchSchoolId == 2) {
      this.curGrade = [...this.grade];
    } else {
      this.curGrade = this.grade.filter((item: any): boolean => {
        return item.text.indexOf("高") < 0;
      });
    }
    this.curGrade.map((itm: any): void => {
      if (itm.text == "全部年级") {
        itm.text = "All grades";
      }
    });
  }
  private initFilterSubject(): void {
    console.log(this.subject, '学科')
    this.curSubject = [];
    if (this.branchSchoolId == 2) {
      this.curSubject = [...this.subject];
    } else {
      this.curSubject = this.subject.filter((item: any): boolean => {
        return item.text != "英语";
      });
    }
  }
  // 根据学科筛选年级
  private filterGrade(): void {
    const _this: any = this,
      _arr: Array<any> = [...this.grade];
    this.curGrade = [];
    if (this.branchSchoolId == 2) {
      if (
        this.queryObj.subject == "1" ||
        this.queryObj.subject == "" ||
        this.queryObj.subject == 6
      ) {
        this.curGrade = _arr;
      }
      if (this.queryObj.subject == "3") {
        this.curGrade = _arr.filter((item: any): boolean => {
          return item.text.indexOf("年级") < 0 && item.text != "幼大班";
        });
      }
      if (this.queryObj.subject == "4") {
        this.curGrade = _arr.filter((item: any): boolean => {
          return (
            item.text.indexOf("年级") < 0 &&
            item.text != "幼大班" &&
            item.text != "初一"
          );
        });
      }
    } else {
      // 数 1, 理 3, 化 4, 英 6
      if (this.queryObj.subject == "1" || this.queryObj.subject == "") {
        this.curGrade = _arr.filter((item: any): boolean => {
          return item.text.indexOf("高") < 0;
        });
      }
      if (this.queryObj.subject == "3") {
        this.curGrade = _arr.filter((item: any): boolean => {
          return item.id.indexOf("2-") >= 0 || item.text == "All grades";
        });
      }
      if (this.queryObj.subject == "4") {
        this.curGrade = _arr.filter((item: any): boolean => {
          return (
            (item.id.indexOf("2-") >= 0 && item.text != "初一") ||
            item.text == "All grades"
          );
        });
      }
    }
  }
  // 根据年级筛选学科
  private filterSubject(): void {
    const _this: any = this;
    this.curSubject = [];
    if (this.branchSchoolId == 2) {
      if (
        this.queryObj.grade.indexOf("1-") >= 0 ||
        this.queryObj.grade.indexOf("4-") >= 0
      ) {
        _this.subject.forEach((item: any): void => {
          if (item.text != "化学" && item.text != "物理") {
            _this.$set(_this.curSubject, _this.curSubject.length, item);
          }
        });
      } else {
        if (this.queryObj.grade.indexOf("2-") >= 0) {
          _this.subject.forEach((item: any): void => {
            if (item.text != "化学") {
              _this.$set(_this.curSubject, _this.curSubject.length, item);
            }
          });
        } else {
          this.curSubject = [...this.subject];
        }
      }
    } else {
      if (
        this.queryObj.grade.indexOf("1-") >= 0 ||
        this.queryObj.grade.indexOf("4-") >= 0
      ) {
        _this.subject.forEach((item: any): void => {
          if (item.text == "数学" || item.text == "All subjects") {
            _this.$set(_this.curSubject, _this.curSubject.length, item);
          }
        });
      } else if (this.queryObj.grade == "2-1") {
        _this.subject.forEach((item: any): void => {
          if (
            item.text == "数学" ||
            item.text == "物理" ||
            item.text == "All subjects"
          ) {
            _this.$set(_this.curSubject, _this.curSubject.length, item);
          }
        });
      } else if (this.queryObj.grade == "2-2" || this.queryObj.grade == "2-3") {
        _this.subject.forEach((item: any): void => {
          if (item.text != "英语") {
            _this.$set(_this.curSubject, _this.curSubject.length, item);
          }
        });
      } else {
        this.initFilterSubject();
      }
    }
  }
  private delQueryOption(attr: string, type: number): void {
    this.$set(this.activeFlaglagArr, type, 0);
    this.$set(this.queryObjStr, attr, "");
    this.$set(this.queryObj, attr, "");

    this.queryObj.page = 1;

    if (attr == "grade") {
      this.initFilterSubject();
    }
    if (attr == "subject") {
      this.initFilterGrade();
    }
    this.formatLocalstorage();
    this.getCourse(0);
  }

  private delAllEvent() {
    this.activeFlaglagArr.forEach((v: number, i: number): void => {
      this.$set(this.activeFlaglagArr, i, -1);
    });
    const _arr = ["year", "grade", "subject", "termtype"];
    Object.keys(this.queryObjStr).forEach((attr: string): void => {
      if (_arr.includes(attr)) {
        this.$set(this.queryObjStr, attr, "");
      }
    });
    Object.keys(this.queryObj).forEach((attr: string): void => {
      if (_arr.includes(attr)) {
        this.$set(this.queryObj, attr, "");
      }
    });
    this.queryObj.page = 1;
    this.initFilterGrade();
    this.initFilterSubject();
    this.formatLocalstorage();
    this.getCourse(0);
  }

  private gotoDetailEvent(item: any): void {
    sessionStorage.removeItem('fangtian_person_to_course_orderid');
    const _this: any = this;
    _this.$router.push({
      path: "/course/detail",
      query: { courseid: item.course_id }
    });
  }

  private initQueryObj(): void {
    const fangtian_active_flaglag_arr: any = localStorage.getItem(
        "fangtian_active_flaglag_arr"
      )
        ? localStorage.getItem("fangtian_active_flaglag_arr")
        : "0, 0, 0, 0",
      fangtian_curCityPosition: any = localStorage.getItem(
        "fangtian_curCityPosition"
      ),
      fangtian_course_query: any = localStorage.getItem(
        "fangtian_course_query"
      ),
      fangtian_course_query_obj_str: any = localStorage.getItem(
        "fangtian_course_query_obj_str"
      );
    if (fangtian_active_flaglag_arr) {
      const _arr: Array<string> = fangtian_active_flaglag_arr.split(",");
      this.activeFlaglagArr = _arr.map(v => Number(v));
    }
    if (fangtian_course_query) {
      this.queryObj = JSON.parse(fangtian_course_query);
    } else {
      const _cur: any = new Date(),
        _year: any = _cur.getFullYear();
      this.queryObj.year = _year;
      this.yearList.forEach((item: string, index: number): void => {
        if (item == _year) {
          this.activeFlaglagArr[0] = index;
        }
      });
    }
    if (fangtian_curCityPosition) {
      this.queryObj.branch_school_id = JSON.parse(fangtian_curCityPosition).id;
    }
    if (fangtian_course_query_obj_str) {
      this.queryObjStr = { ...JSON.parse(fangtian_course_query_obj_str) };
    } else {
      this.queryObjStr.year = this.queryObj.year;
    }
    this.getCourse(0);
  }

  private changeRouteToTestNear(type: number): void {
    sessionStorage.removeItem('fangtian_person_to_course_orderid');
    const _this: any = this;
    if (type == 1) {
      _this.$router.push(`/course/detail?courseid=0`);
    } else {
      _this.$router.push(`/nearbyCampus`);
    }
  }

  mounted(): void {
    this.getYears();
    getBranchschools();
    if (this.personInfo) {
        this.personInfo = JSON.parse(this.personInfo)
    }
  }
  beforeDestroy() {
    this.allTotalPage = 0;
    this.queryObj.page = 1;
  }
}
